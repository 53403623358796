import {createRef, useRef, useState} from 'react';
import './App.css';

const imageFolder = require.context('../public/images', true);

const images = [
  {
    filename: 'IMG_0037.jpg',
    alt: 'At The Music Man',
  },
  {
    filename: 'IMG_3336.jpg',
    alt: '',
  },
  {
    filename: 'IMG_3507.jpg',
    alt: '',
  },
  {
    filename: 'IMG_4171.jpg',
    alt: '',
  },
  {
    filename: 'IMG_4472.jpg',
    alt: '',
  },
  {
    filename: 'IMG_4810.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5057.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5058.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5333.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5400.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5417.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5510.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5556.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5564.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5687.jpg',
    alt: '',
  },
  {
    filename: 'IMG_5797.jpg',
    alt: '',
  },
  {
    filename: 'IMG_6194.jpg',
    alt: '',
  },
  {
    filename: 'IMG_6786.jpg',
    alt: '',
  },
  {
    filename: 'IMG_6858.jpg',
    alt: '',
  },
  {
    filename: 'IMG_6921.jpg',
    alt: '',
  },
  {
    filename: 'IMG_7334.jpg',
    alt: '',
  },
  {
    filename: 'IMG_7398.jpg',
    alt: '',
  },
  {
    filename: 'IMG_7454.jpg',
    alt: '',
  },
  {
    filename: 'IMG_7706.jpg',
    alt: '',
  },
  {
    filename: 'IMG_7757.jpg',
    alt: '',
  },
  {
    filename: 'IMG_7989.jpg',
    alt: '',
  },
  {
    filename: 'IMG_8105.jpg',
    alt: '',
  },
  {
    filename: 'IMG_8158.jpg',
    alt: '',
  },
  {
    filename: 'IMG_8199.jpg',
    alt: '',
  },
  {
    filename: 'IMG_E3374.jpg',
    alt: '',
  },
  {
    filename: 'IMG_E4380.jpg',
    alt: '',
  },
  {
    filename: 'IMG_E6305.jpg',
    alt: '',
  },
]

function App() {
  const [currentImage, setCurrentImage] = useState(null);
  const imagesRef = useRef(images.map(() => createRef()));

  function scroll(index) {
    const imgRef = imagesRef.current[index];
    if (imgRef.current.getBoundingClientRect().top < 0) {
      imgRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
    } else if (imgRef.current.getBoundingClientRect().bottom > window.innerHeight) {
      imgRef.current.scrollIntoView({block: 'end', behavior: 'smooth'});
    }
  }

  const handleClick = (index) => {
    if (currentImage === index) {
      setCurrentImage(null);
    } else {
      setCurrentImage(index);
      setTimeout(() => scroll(index), 750);
    }
  }

  return (
    <div className="App">
      <div className="background"></div>
      <div className="imagesPanel">
        <div className="images">
          {
            images.map((image, index) => {
              const evenodd = index % 2 === 0 ? 'odd' : 'even';
              const selected = index === currentImage ? 'selected' : 'not-selected';
              const rotate = Math.random() * 10;
              const style = {
                transform: evenodd === 'odd'
                  ? `translateX(40%) rotate(${7 + rotate}deg)`
                  : `translateX(-40%) rotate(${-7 - rotate}deg)`
              }
              return (
                <div
                  className={`imageBox ${evenodd} ${selected}`}
                  key={image.filename}
                  onClick={() => handleClick(index)}
                  style={style}
                >
                  <img
                    className="image"
                    ref={imagesRef.current[index]}
                    src={imageFolder(`./${image.filename ?? ''}`)} alt={image.alt ?? ''}
                  />
                </div>
              )
            })
          }
        </div>
      </div>
      {/* <div className="content">
        Hi
      </div> */}
    </div>
  );
}

export default App;
